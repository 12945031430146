<template>
  <div id="guest-view-order">
    <div v-if="!orderFetchError" class="order-view-container">
      <ViewOrder @on-back="changePage()" :order-obj="order" />
    </div>
    <div v-else class="error-container">
      {{ orderFetchError }}
    </div>
  </div>
</template>

<script>
import ViewOrder from "@theme/components/organisms/view-order";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      order: null,
      orderFetchError: null,
    };
  },
  components: {
    ViewOrder,
  },
  methods: {
    ...mapActions({
      getOrderUsingNotificationToken: "guest/getOrderUsingNotificationToken",
    }),
    changePage() {
      if (window.history.length > 1) {
        //this.$router.back();
        this.$router.push({
          name: "home",
        });
      } else {
        this.$router.push({
          name: "home",
        });
      }
    },
  },
  async created() {
    const queryParams = this.$route.query;
    if (!queryParams || !queryParams.notifToken || !queryParams.orderId) {
      this.orderFetchError =
        "Could not fetch the requested order due to missing details in the URL.";
    }
    const {
      success,
      order,
      errorMessage,
    } = await this.getOrderUsingNotificationToken({
      orderId: queryParams.orderId,
      token: queryParams.notifToken,
    });
    if (success && order) {
      this.order = order;
    } else if (success && !order) {
      this.orderFetchError =
        "Could not find the requested order. Please check the order number and retry";
    } else {
      this.orderFetchError = errorMessage;
    }
  },
};
</script>

<style lang="scss" scoped>
@use "~@lib/styles/helpers/breakpoints" as *;

#guest-view-order {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;

  @include for-desktop {
    max-width: 976px;
    margin: 0 auto;
  }
}
</style>
